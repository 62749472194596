@import url('https://fonts.googleapis.com/css2?family=Arima:wght@700&family=Piazzolla:opsz,wght@8..30,600&family=Ubuntu:wght@300;400&display=swap');
*{
  font-family: 'Ubuntu', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card:hover{
  
  box-shadow: 2px 2px 10px #000;
  background-color:#282c34;
  color: white;

}

.btn{
  transition: 0.5s;
}
.btn:hover{
  transform: scale(1.1);
}
@keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
